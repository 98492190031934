
<template>
  <div class="arrow-button">
    <div role="button" class="arrow-button__main" :class="{disabled}" @click="onClick">
      <div class="arrow-button__main-arrow"
           :class="{'arrow-button__main-arrow--left': direction === 'left'}"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSound } from '@vueuse/sound'
import click from '~/assets/sounds/click.mp3'
const { play } = useSound(click)
export interface ArrowButtonProperties {
  direction: 'left'|'right',
}

const props = defineProps<ArrowButtonProperties>()
const emit = defineEmits(['click'])

const disabled = ref(true)
setTimeout(() => {
  disabled.value = false
}, 333)
const onClick = (event: MouseEvent) => {
  if (!disabled.value) {
    play()
    emit('click', event)
  }
}
</script>

<style lang="css" scoped>
@import "../../assets/styles/main.css";

.arrow-button {
  position: relative;
  /*width: 100px;
  height: 169px;*/
  pointer-events: auto;
}

.arrow-button__main {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &.disabled {
    cursor: none;
    opacity: .6;
  }
}

.arrow-button__main-arrow {
  position: relative;
  width: 80px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/ui/buttons/arrow_right.png");
}

.arrow-button__main-arrow--left {
  background-image: url("@/assets/images/ui/buttons/arrow_left.png");
}
</style>
